import { useEffect, useState } from "react";

import { Button, type ButtonAsAnchor } from "@/components/ui/Button.tsx";
import { ROUTER } from "@/utils/routes.ts";
import { cookieNames, readCookie } from "@/utils/storage.ts";

type FormLinkProps = ButtonAsAnchor & {
  registeredLink?: string;
  unregisteredLink?: string;
};

export const FormLink = ({
  children,
  registeredLink = ROUTER.form,
  unregisteredLink = ROUTER.registration,
  ...props
}: FormLinkProps) => {
  const [url, setUrl] = useState(unregisteredLink);

  useEffect(() => {
    if (readCookie(cookieNames.sessionActive) === "true") {
      setUrl(registeredLink);
    }
  }, []);

  return (
    <Button href={url} {...props}>
      {children}
    </Button>
  );
};
